import { Copyright } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import React from 'react'

function Footer() {
  return (
    <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
      <Typography
        variant="subtitle1"
        align="center"
        color="text.secondary"
        component="p"
      >
        {/* copy right */}
        Ryunosuke-watanabe © {new Date().getFullYear()}
      </Typography>
    </Box>
  )
}

export default Footer