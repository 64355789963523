import { Alert, Snackbar, Stack, Typography } from '@mui/material'
import React from 'react'
import { TextField, Button } from '@mui/material'
import './index.scss'
import EmailIcon from '@mui/icons-material/Email';
import TwitterIcon from '@mui/icons-material/Twitter';
import { motion } from 'framer-motion'
import { init, sendForm, send } from 'emailjs-com';

function Contact({
  setCursorContent,
  setCursorVariant,
}: {
  setCursorContent: (content: string) => void
  setCursorVariant: (variant: string) => void
}) {
  const [contact, setContact] = React.useState({
    name: '',
    email: '',
    message: '',
  })
  const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState(false)

  const onSubmit = (e: any) => {
    e.preventDefault()

    const userID: any = process.env.REACT_APP_PORTFOLIO_EMAILJS_USER_ID
    const serviceID: any = process.env.REACT_APP_PORTFOLIO_EMAILJS_SERVICE_ID
    const templateID: any = process.env.REACT_APP_PORTFOLIO_EMAILJS_TEMPLATE_ID

    if ((userID && serviceID && templateID) && (contact.name && contact.email && contact.message)) {
      init(userID)

      const templateParams = {
        from_name: contact.name,
        email: contact.email,
        message: contact.message,
      }

      send(serviceID, templateID, templateParams).then(
        (response) => {
          setOpen(true)
          setContact({
            name: '',
            email: '',
            message: '',
          })
        }
      ).catch((err) => {
        console.log('FAILED...', err)
      })
    } else {
      setError(true)
    }
  }

  return (
    <section
      id="contact"
      onMouseEnter={() => {
        setCursorVariant('onContent')
        setCursorContent('Contact')
      }}
      onMouseLeave={() => {
        setCursorVariant('default')
        setCursorContent('')
      }}
    >
      <Typography
        variant="h2"
        component="h2"
        sx={{
          paddingLeft: '1rem',
          paddingTop: '1rem',
          fontWeight: 'bold',
        }}
      >
        Contact
      </Typography>
      <Stack
        justifyContent="center"
        alignItems="center"
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <div className="contact-form">
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            value={contact.name}
            onChange={(e) => {
              setContact({ ...contact, name: e.target.value })
            }}
            sx={{ width: '100%', marginBottom: '1rem' }}
          />
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            value={contact.email}
            onChange={(e) => {
              setContact({ ...contact, email: e.target.value })
            }}
            sx={{ width: '100%', marginBottom: '1rem' }}
          />
          <TextField
            id="outlined-basic"
            label="Message"
            variant="outlined"
            value={contact.message}
            onChange={(e) => {
              setContact({ ...contact, message: e.target.value })
            }}
            sx={{ width: '100%', marginBottom: '1rem' }}
          />
          <Button variant="contained" sx={{ width: '100%' }}
            onClick={(e: any) => {
              onSubmit(e)
            }}
            onMouseEnter={() => {
              setCursorVariant('clickMe')
              setCursorContent('Send')
            }}
            onMouseLeave={() => {
              setCursorVariant('onContent')
              setCursorContent('Contact')
            }}
          >
            Send
          </Button>
        </div>
        <div
          className="contact-info"
        >
          <motion.div
            className="contact-info-item"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onMouseEnter={() => {
              setCursorVariant('clickMe')
              setCursorContent('Email')
            }}
            onMouseLeave={() => {
              setCursorVariant('onContent')
              setCursorContent('Contact')
            }}
          >
            <EmailIcon sx={{ fontSize: 40 }} />
            <Typography variant="h5" component="h5">
              Email
            </Typography>
            <Typography variant="body1" component="p"
              sx={{
                '& a': {
                  color: 'inherit',
                  textDecoration: 'none',
                },
              }}
            >
              <a href="mailto:
              ">ryunosuke.445@gmail.com</a>
            </Typography>
          </motion.div>
          <motion.div
            className="contact-info-item"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onMouseEnter={() => {
              setCursorVariant('clickMe')
              setCursorContent('Twitter')
            }}
            onMouseLeave={() => {
              setCursorVariant('onContent')
              setCursorContent('Contact')
            }}
          >
            <TwitterIcon sx={{ fontSize: 40 }} />
            <Typography variant="h5" component="h5">
              Twitter
            </Typography>
            <Typography variant="body1" component="p"
              sx={{
                '& a': {
                  color: 'inherit',
                  textDecoration: 'none',
                },
              }}
            >
              <a href="https://twitter.com/@445Ryunosuke" target="_blank" rel="noreferrer">
                @445Ryunosuke
              </a>
            </Typography>
          </motion.div>
        </div>
      </Stack>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => {
          setOpen(false)
        }}
      >
        <Alert
          severity='success'
          sx={{ width: '100%' }}
          onClose={() => {
            setOpen(false)
          }}
        >
          Thank you for your message!
        </Alert>
      </Snackbar>
      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={() => {
          setError(false)
        }}
      >
        <Alert
          severity='error'
          sx={{ width: '100%' }}
          onClose={() => {
            setError(false)
          }}
        >
          Please fill out all fields
        </Alert>
      </Snackbar>
    </section>
  )
}

export default Contact