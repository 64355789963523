import { Button, Card, CardActions, CardContent, CardHeader, Typography, Modal, Box, Chip, Container } from '@mui/material'
import { Stack } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { getWorks } from 'services'
import './index.scss'
import { motion, useScroll, useTransform } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

function Works({
  setCursorContent,
  setCursorVariant,
}: {
  setCursorContent: (content: string) => void
  setCursorVariant: (variant: string) => void
}) {
  const [works, setWorks] = useState([] as any)
  const [open, setOpen] = useState(false)
  const [selectedWork, setSelectedWork] = useState({} as any)

  useEffect(() => {
    getWorks().then((res) => {
      setWorks(res.items)
    })
  }, [])

  let ref = React.useRef(null)

  let { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start start', 'end start'],
  })

  let y = useTransform(scrollYProgress, [0, 1], ['0', '100%'])
  let opacity = useTransform(scrollYProgress, [0, 0.9, 1], [0.5, 0.5, 0])

  return (
    <>
      <section
        id="works"
        onMouseEnter={() => {
          setCursorVariant('onContent')
          setCursorContent('Works')
        }}
        onMouseLeave={() => {
          setCursorVariant('default')
          setCursorContent('')
        }}
        ref={ref}
      >
        <Typography
          variant="h2"
          component="h2"
          sx={{
            paddingLeft: '1rem',
            paddingTop: '1rem',
            fontWeight: 'bold',
          }}
        >
          Works
        </Typography>
        <Stack
          flexWrap={{ xs: 'wrap', md: 'wrap' }}
          flexDirection={{ xs: 'column', md: 'column' }}
          justifyContent="center"
          alignItems="center"
        >
          {works.length > 0 &&
            works.map((item: any, index: number) => {
              return (
                <Container
                  key={index}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    style={{
                      backgroundImage: `url(${item.fields.image.fields.file.url})`,
                      width: '100vw',
                      height: 100 / works.length + 'vh',
                      position: 'absolute',
                      left: 0,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      zIndex: -1,
                      y,
                      opacity,
                    }}
                  />
                  <Card
                    sx={{
                      width: '600px',
                      maxWidth: '90%',
                      margin: '1rem',
                      overflow: 'hidden',
                      pb: '2rem',
                      ":hover": {
                        transform: 'scale(1.05)',
                      },
                      transition: 'transform 0.5s',
                    }}
                    onMouseEnter={() => {
                      setCursorVariant('clickMe')
                      setCursorContent('click!!')
                    }}
                    onMouseLeave={() => {
                      setCursorVariant('onContent')
                      setCursorContent('Works')
                    }}
                    onClick={() => {
                      setOpen(true)
                      setSelectedWork(item)
                    }}
                  >
                    <CardHeader
                      title={item.fields.title}
                    />
                    <CardContent
                      sx={{
                        height: '250px',
                      }}
                    >
                      {item.fields.image.fields.file.url && <img
                        src={item.fields.image.fields.file.url}
                        alt={item.fields.image.fields.title}
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                      />}
                    </CardContent>
                  </Card>
                </Container>
              )
            })}
        </Stack>
      </section>
      {selectedWork.fields &&
        <Modal
          open={open}
          onClose={() => setOpen(false)}
        >
          <Box
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 800,
              maxWidth: '90%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: '10px',
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {selectedWork.fields.title}
            </Typography>
            {selectedWork.fields.image.fields.file.url && <img
              src={selectedWork.fields.image.fields.file.url}
              alt={selectedWork.fields.image.fields.title}
              style={{
                width: '100%',
                height: 'auto',
              }}
            />}
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {selectedWork.fields.content}
            </Typography>
            <Typography
              variant="h6"
              component="h2"
              sx={{
                marginTop: '1rem',
              }}
            >
              Usage Technology
            </Typography>
            {selectedWork.fields.skills.map((item: any, index: number) => {
              return (
                <Chip
                  key={index}
                  label={item}
                  sx={{
                    margin: '0.5rem',
                  }}
                />
              )
            })}
          </Box>
        </Modal>}
    </>
  )
}

export default Works