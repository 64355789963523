import { client } from 'utils/client';
import { ISkillFields, ICarrerFields, IWorksFields } from '../../@types/generated/contentful';

export const getSkills = async () => {
    const skills = await client.getEntries<ISkillFields>({
        content_type: 'skill',
    });
    return skills
}

export const getCarrer = async () => {
    const carrer = await client.getEntries<ICarrerFields>({
        content_type: 'carrer',
        order: 'fields.startDate',
    });
    return carrer
}

export const getWorks = async () => {
    const works = await client.getEntries<IWorksFields>({
        content_type: 'works',
    });
    return works
}