import { Typography, Card, CardContent, CardHeader, Stack, Modal, Box, CardActions, Button, Chip, } from '@mui/material'
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import React from 'react'
import { getSkills } from 'services'
import './index.scss'
import { motion, useScroll, useTransform } from 'framer-motion'


function Skills({
  setCursorContent,
  setCursorVariant,
}: {
  setCursorContent: (content: string) => void
  setCursorVariant: (variant: string) => void
}) {
  const [skills, setSkills] = React.useState([] as any)
  const [open, setOpen] = React.useState(false)
  const [selectedSkill, setSelectedSkill] = React.useState({} as any)

  React.useEffect(() => {
    getSkills().then((res) => {
      setSkills(res.items)
    })
  }, [])

  let ref = React.useRef(null)
  let { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end start'],
  })

  let x = useTransform(scrollYProgress, [0, 1], ['-100vw', '100vw'])


  return (
    <section
      id="skills"
      onMouseEnter={() => {
        setCursorVariant('onContent')
        setCursorContent('Skills')
      }}
      onMouseLeave={() => {
        setCursorVariant('default')
        setCursorContent('')
      }}
      ref={ref}
    >
      <motion.div
        style={{
          x,
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: '#90caf9',
          zIndex: -1,
          clipPath: 'polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)',
        }}
      />
      <Typography
        variant="h2"
        component="h2"
        sx={{
          paddingLeft: '1rem',
          paddingTop: '1rem',
          fontWeight: 'bold',
        }}
      >
        Skills
      </Typography>
      <Stack
        flexWrap={'wrap'}
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="center"
        alignItems="center"
      >
        {
          skills.length > 0 &&
          skills.map((item: any, index: number) => {
            return (
              <Card
                key={index}
                sx={{
                  width: '300px',
                  maxWidth: '90%',
                  margin: '1rem',
                  ":hover": {
                    transform: 'scale(1.05)',
                  },
                  transition: 'all 0.3s ease-in-out',
                }}
                onClick={() => {
                  setOpen(true)
                  setSelectedSkill(item)
                }}
                onMouseEnter={() => {
                  setCursorVariant('clickMe')
                  setCursorContent('Click!!')
                }}
                onMouseLeave={() => {
                  setCursorVariant('onContent')
                  setCursorContent('Skills')
                }}
              >
                <CardHeader
                  title={item.fields.name}
                />
                <CardContent>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {(() => {
                      let stars = []
                      for (let i = 0; i < item.fields.level; i++) {
                        stars.push(
                          <StarIcon
                            key={i}
                            sx={{
                              color: '#FFD700',
                            }}
                          />
                        )
                      }
                      for (let i = 0; i < 10 - item.fields.level; i++) {
                        stars.push(
                          <StarBorderIcon
                            key={i + 10}
                            sx={{
                              color: '#FFD700',
                            }}
                          />
                        )
                      }
                      return stars
                    })()}
                  </Stack>
                </CardContent>
                {/* {item.fields.subskills &&
                  <CardActions>
                    <Button
                      onClick={() => {
                        setOpen(true)
                        setSelectedSkill(item)
                      }}
                    >
                      Learn More
                    </Button>
                  </CardActions>} */}
              </Card>
            )
          })
        }
      </Stack>
      {selectedSkill.fields &&
        <Modal
          open={open}
          onClose={() => {
            setOpen(false)
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 800,
              maxWidth: '90%',
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2"
              sx={{
                fontWeight: 'bold',
                textAlign: 'center',
                p: 2,
              }}
            >
              Sub Skills
            </Typography>
            <Stack
              flexWrap={'wrap'}
              direction="row"
              justifyContent={'center'}
            >
              {
                selectedSkill.fields.subskills &&
                selectedSkill.fields.subskills.map((item: any, index: number) => {
                  return (
                    <Chip
                      key={index}
                      label={item}
                      sx={{
                        margin: '0.5rem',
                      }}
                    />
                  )
                })
              }
            </Stack>
          </Box>
        </Modal>}
    </section>
  )
}

export default Skills