import React, { useEffect, useState } from 'react'
import Carrer from 'components/carrer';
import Footer from 'components/footer';
import Header from 'components/header';
import Intro from 'components/intro';
import Skills from 'components/skills';
import Works from 'components/works';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMouse from '@react-hook/mouse-position';
import { motion } from 'framer-motion';
import Contact from 'components/contant';

function App() {
  const [mode, setMode] = React.useState('light' as 'dark' | 'light');
  const [blendmode, setBlendMode] = React.useState('normal' as 'normal' | 'multiply' | 'screen' | 'overlay' | 'darken' | 'lighten' | 'color-dodge' | 'color-burn' | 'hard-light' | 'soft-light' | 'difference' | 'exclusion' | 'hue' | 'saturation' | 'color' | 'luminosity')
  useEffect(() => {
    // 時間によってテーマを変更
    const now = new Date();
    const hour = now.getHours();
    if (hour >= 18 || hour <= 6) {
      setMode('dark');
      setBlendMode('screen');
    }
  }, []);

  const darkTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
        typography: {
          fontFamily: [
            'Jockey One',
            'sans-serif'
          ].join(','),
        },
      }),
    [mode]
  );
  const [cursorVariant, setCursorVariant] = useState("default");
  const [cursorContent, setCursorContent] = useState("");
  const [opacity, setOpacity] = useState(1);

  const ref = React.useRef(null);

  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
  });

  let mouseXPosition: any = 0;
  let mouseYPosition: any = 0;

  if (mouse.x !== null) {
    mouseXPosition = mouse.clientX;
  }

  if (mouse.y !== null) {
    mouseYPosition = mouse.clientY;
  }

  useEffect(() => {
    if (mouseXPosition === 0 && mouseYPosition === 0) {
      setOpacity(0);
    } else {
      setOpacity(1);
    }
  }, [mouseXPosition, mouseYPosition]);

  const variants = {
    default: {
      // opacity: 1,
      height: 10,
      width: 10,
      fontSize: "16px",
      backgroundColor: "#1e91d6",
      x: mouseXPosition,
      y: mouseYPosition,
      transition: {
        type: "spring",
        mass: 0.6
      }
    },
    onContent: {
      // opacity: 1,
      backgroundColor: "#1e91d6",
      height: 80,
      width: 80,
      fontSize: "18px",
      x: mouseXPosition - 32,
      y: mouseYPosition - 32
    },
    clickMe: {
      // opacity: 1,
      backgroundColor: "#1e91d6",
      height: 150,
      width: 150,
      fontSize: "24px",
      x: mouseXPosition - 62,
      y: mouseYPosition - 62
    },
  };

  const spring = {
    type: "spring",
    stiffness: 500,
    damping: 28
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <main ref={ref}>
        <motion.div
          className="cursor"
          variants={variants}
          animate={cursorVariant}
          transition={spring}
          style={{
            opacity: opacity,
            mixBlendMode: blendmode,
          }}
        >
          <span className='cursorText'>{cursorContent}</span>
        </motion.div>
        <Header
          sections={[
            { title: 'About', url: '#intro' },
            { title: 'Carrer', url: '#carrer' },
            { title: 'Works', url: '#works' },
            { title: 'Skills', url: '#skills' },
          ]}
          title="Portfolio"
          mode={mode}
          setMode={setMode}
          setBlendMode={setBlendMode}
        />
        <Intro
          setCursorContent={setCursorContent}
          setCursorVariant={setCursorVariant}
        />
        <Carrer
          setCursorContent={setCursorContent}
          setCursorVariant={setCursorVariant}
        />
        <Works
          setCursorContent={setCursorContent}
          setCursorVariant={setCursorVariant}
        />
        <Skills
          setCursorContent={setCursorContent}
          setCursorVariant={setCursorVariant}
        />
        <Contact
          setCursorContent={setCursorContent}
          setCursorVariant={setCursorVariant}
        />
        <Footer />
      </main>
    </ThemeProvider>
  )
}

export default App