import React, { useRef } from 'react'
import './index.scss'
import { motion, useScroll, useTransform, AnimatePresence, useMotionValue } from 'framer-motion'
import { Typography } from '@mui/material'
import useMouse from '@react-hook/mouse-position'

function Intro({
    setCursorVariant,
    setCursorContent,
}: {
    setCursorVariant: (variant: string) => void;
    setCursorContent: (content: string) => void;
}) {
    const ref = useRef(null)
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ['start start', 'end start'],
    })
    let y = useTransform(scrollYProgress, [0, 1], ["0", "100vh",])
    let opacity = useTransform(scrollYProgress, [0, 0.5, 1], [0.5, 0.5, 0])

    return (
        <section
            id="intro"
            ref={ref}
            onMouseEnter={() => {
                setCursorVariant('onContent')
                setCursorContent('Profile')
            }}
            onMouseLeave={() => {
                setCursorVariant('default')
                setCursorContent('')
            }}
        >
            <AnimatePresence>
                <motion.img
                    initial={{
                        opacity: 0,
                    }}
                    whileInView={{
                        opacity: 0.5,
                        transition: {
                            duration: 1,
                        },
                    }}
                    className='profile'
                    src='/images/profile.jpg'
                />
            </AnimatePresence>
            <Typography
                variant="h1"
                component="p"
                sx={{
                    pl: 1,
                    pt: 5,
                    pr: 1,
                    maxWidth: '100%',
                    fontWeight: 'bold',
                    fontSize: '6rem',
                    zIndex: 10,
                    wordBreak: 'break-all',
                    ":hover": {
                        fontSize: '7rem',
                    },
                    transition: 'all 0.5s ease',
                }}
            >
                Ryunosuke Watanabe
            </Typography>
            <Typography
                variant="body1"
                component="p"
                sx={{
                    fontSize: '3rem',
                    pl: 1,
                    pr: 1,
                    pt: 2,
                    zIndex: 10,
                    ":hover": {
                        fontSize: '3.5rem',
                    },
                    transition: 'all 0.5s ease',
                }}
            >
                I'm a software engineer based in Tokyo, Japan.
            </Typography>
            <motion.div
                animate={{
                    top: 0,
                    transition: {
                        duration: 8,
                        repeat: Infinity,
                        repeatType: 'reverse',
                    },
                }}
                className='circle'
                style={{
                    y,
                    opacity,
                    overflow: 'hidden',
                }}
            />
        </section>
    )
}

export default Intro