import { Card, CardContent, CardHeader, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getCarrer } from 'services'
import { motion, useScroll, useTransform } from 'framer-motion'
import './index.scss'
import { Stack } from '@mui/system'

function Carrer({
	setCursorContent,
	setCursorVariant,
}: {
	setCursorContent: (content: string) => void
	setCursorVariant: (variant: string) => void
}) {
	const [carrer, setCarrer] = useState([] as any)

	useEffect(() => {
		getCarrer().then((res) => {
			setCarrer(res.items)
		})
	}, [])
	const ref = React.useRef(null)
	const { scrollYProgress } = useScroll({
		target: ref,
		offset: ['start start', 'end end'],
	})

	let y = useTransform(scrollYProgress, [0, 1], ["0", "100vh"])
	let opacity = useTransform(scrollYProgress, [0, 1], [1, 0.5])

	return (
		<section
			id="carrer"
			ref={ref}
			onMouseEnter={() => {
				setCursorVariant('onContent')
				setCursorContent('Carrer')
			}}
			onMouseLeave={() => {
				setCursorVariant('default')
				setCursorContent('')
			}}
		>
			<Typography
				variant="h2"
				component="h2"
				sx={{
					paddingLeft: '1rem',
					paddingTop: '1rem',
					fontWeight: 'bold',
				}}
			>
				Carrer
			</Typography>
			<Stack
				justifyContent="center"
				alignItems="center"
			>
				{carrer.length > 0 &&
					carrer.map((item: any, index: number) => {
						const startDate = new Date(item.fields.startDate)
						const endDate = new Date(item.fields.endDate)

						const formatDate = (date: Date) => {
							// YYYY/MM
							return date.getFullYear() + "/" + ("00" + (date.getMonth()+1)).slice(-2)
						}
						return (
							<Stack
								key={index}
								alignItems="center"
							>
								<Typography
									variant="body1"
									component="p"
									sx={{
										fontSize: '3rem',
										paddingTop: '1rem',
									}}
								>
									{(formatDate(startDate) + " ~ " + 
									(!Number.isNaN(endDate.getTime()) ? formatDate(endDate) : "Present"))}
								</Typography>
								<Card
									sx={{
										width: '600px',
										maxWidth: '90vw',
										margin: '1rem',
									}}
								>
									<CardContent
										sx={{
											padding: '1rem',
										}}
									>
										<CardHeader
											title={item.fields.name}
											sx={{
												fontSize: '1.5rem',
												textAlign: 'center',
											}}
										/>
										{
											item.fields.description &&
											item.fields.description.content.map((desc: any, index: number) => {
												return (
													<Typography
														key={index}
														variant="body1"
														component="p"
														sx={{
															fontSize: '1rem',
														}}
													>
														{desc.content[0].value}
													</Typography>
												)
											})
										}
									</CardContent>
								</Card>
							</Stack>
						)
					})
				}
			</Stack>
			<motion.div
				className='carrer-background'
				style={{
					clipPath: 'polygon(100% 75%, 50% 100%, 0 75%, 0 0, 50% 25%, 100% 0)',
					y: y,
					opacity: opacity,
				}}
			/>
		</section>
	)
}

export default Carrer